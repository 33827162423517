import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { updatePageTitle } from "../../reducers/page";
import "./Home.css";
import Section from "../../Components/Content/Section";
import { Parallax } from 'react-parallax';
import ModalVideo from 'react-modal-video';
import demoVideoImage from "../../assets/img/banners/TacAO_banner_1_1792x912.jpg";
import mobileAppMerchantImage1 from "../../assets/img/mobile-app/merchant-step-1-receive-payment-screen.png"
import mobileAppCustomerImage1_2 from "../../assets/img/mobile-app/customer-step-1-2-receive-payment-screen.png"
import mobileAppMerchantImage2 from "../../assets/img/mobile-app/merchant-step-2-enter-amount-screen.png"
import mobileAppMerchantImage3 from "../../assets/img/mobile-app/merchant-step-3-scan-QR-code-screen.png"
import mobileAppCustomerImage3 from "../../assets/img/mobile-app/customer-step-3-scan-QR-code-screen.png"
import mobileAppMerchantImage4 from "../../assets/img/mobile-app/merchant-step-4-payment-confirmed-screen.png"
import mobileAppCustomerImage4 from "../../assets/img/mobile-app/customer-step-4-payment-confirmed-screen.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import FAQ from "../../Components/Content/FAQ";
import PageLinks from "../../Components/Content/PageLinks";
import { H1, P, UL, LI, } from "../../Components/Content/Content";
import Nav from "react-bootstrap/Nav";
import { ReactURLs } from "../../globals/urls";
import AppleAppStore from "../../Components/images/AppleAppStore";
import GooglePlayStore from "../../Components/images/GooglePlayStore";


class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			...{className: props.className},
		};
		this.openModal = this.openModal.bind(this);
		if (this.state.page.pageTitle != Home.defaultProps.page.pageTitle) {
			props.updatePageTitle(Home.defaultProps.page.pageTitle);
		}
	}

	openModal () {
		this.setState({isOpen: true})
	}

	render() {
		const sectionDetails1 = (
			<div>
				<p>
					Accept Bitcoin and Ethereum for purchases,
					<span style={{color: '#FF6600', fontWeight: "bold"}}> get paid in Dollars </span>
					with <span style={{color: '#FF6600', fontWeight: "bold"}}>Zero fees. Same as Cash.</span>
				</p>
				<p>
					The simple, safe and secure way to accept crypto payments for your business.
				</p>
			</div>
		);
		const sectionDetails2 = (
			<div>
				<p>
					Want customers to spend Bitcoin & Ethereum at your business with no risks and no fees? We make it
					simple in 4 easy steps and you get paid in dollars, same as cash.  Boost your sales by
					accepting crypto payments.
				</p>
				<p>
					Get paid with zero fees.
				</p>
			</div>
		);
		const sectionDetails3 = (
			<div>
				<Row>
					<Col xs={1} sm={2} md={0}></Col>
					<Col sx={10} sm={8} md={12}>
						<p>
							<ul>
								<li style={{textAlign: 'left', }}>
									Free easy set up
								</li>
								<li style={{textAlign: 'left', }}>
									You don’t need crypto currency to accept payments
								</li>
								<li style={{textAlign: 'left', }}>
									Simple 4 step process
								</li>
								<li style={{textAlign: 'left', }}>
									No transaction fees, app fees or monthly fees
								</li>
							</ul>

							Our free app makes accepting cryptocurrency for your business safe, secure, and fast!
						</p>
					</Col>
					<Col xs={1} sm={2} md={0}></Col>
				</Row>

			</div>
		);
		const sectionDetails4 = (
			<div>
				<p>
					Join one of our referral or partner programs for ways to grow your business. Be part of this 1.5
					trillion dollar industry that is booming.
				</p>
				<p>
					Get paid in Dollars with Zero Fees!
				</p>
			</div>
		);
		return (
			<div>
				<div>
					<Parallax
						blue={10}
						bgImage={demoVideoImage}
						bgImageAlt="Demo Video"
						strength={300}
					>
						<div className="Home-demo-video-background">
							<Row className="align-items-center" style={{height: "100%"}}>
								<Col>

									<href onClick={this.openModal} className="Home-demo-video-button">
										<FontAwesomeIcon
											icon={faPlayCircle}
											size="3x"
											style={{verticalAlign: "middle"}}
										/> <span style={{textDecoration: "underline", textUnderlineOffset: "0.25em", fontSize: "22px"}}>
										 Watch Now</span>
									</href>
								</Col>
							</Row>
							<ModalVideo
								channel='youtube'
								isOpen={this.state.isOpen}
								videoId='FWDg7PxX1CQ'
								onClose={() => this.setState({isOpen: false})}
							/>
						</div>
					</Parallax>

					<H1>
						TacAO: Elevate Your Airsoft Experience – Now with the Resupply Store!
					</H1>

					<P>
						TacAO is the essential tool for airsoft players who want to take their game to the next level. With real-time team coordination, live map tracking, and customizable objectives, TacAO transforms your airsoft experience into a tactical, immersive battle.
					</P>

					<P>
						And when the game is over, don’t miss a beat! Our new <strong>Resupply Store</strong> is now at your fingertips. Order airsoft BBs, compressed air refills, and other essential consumables directly through the app. With the adrenaline still fresh, restock your gear before your next mission – all in one place.
					</P>

					<P>
						<UL>
							<LI><strong>Real-Time Location Sharing:</strong> Stay connected with your teammates, know their positions, and coordinate more effectively.</LI>
							<LI><strong>Objective Setting:</strong> Create custom objectives with GPS coordinates, track your progress, and earn points for your team.</LI>
							<LI><strong>Live Game Field Mapping:</strong> Draw boundaries, set up objectives, and plan your strategies directly on the field map.</LI>
							<LI><strong>Team Communication:</strong> Use real-time ‘pings’ to communicate important updates and tactical changes without breaking your focus.</LI>
							<LI><strong>Event Scheduling:</strong> Easily schedule games and invite your friends and family, keeping everyone in the loop and ready for action.</LI>
							<LI><strong>Resupply Store:</strong> Resupply with ease. Plan, play, and rearm with TacAO.</LI>
						</UL>
					</P>

					<P>
						With TacAO, you're not just playing airsoft—you're mastering it. Download the app today and get the tactical advantage in every game!
					</P>

					<div
						style={{
							marginBottom: 5
						}}
					>
						<h href={ReactURLs.appStoreApple}>
							<AppleAppStore/>
						</h>
					</div>
					<div
						style={{
							marginBottom: 20
						}}
					>
						<h href={ReactURLs.appStoreGoogle}>
							<GooglePlayStore/>
						</h>
					</div>

				</div>
			</div>

		);
	}
}

Home.defaultProps = {
	isOpen: false,
	page: {
		pageTitle: ""
	}
};

const styles = {
	home: {
	},
	whiteBackgroundColor: {
		backgroundColor: 'white',
		color: '#2A7C6F',
	},
	blueBackgroundColor: {
		backgroundColor: '#2A7C6F',
		color: 'white',
	},
};

const mapStateToProps = (state) => {
	const { page } = state;
	return { page };
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		updatePageTitle,
	}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Home);
